export default {

	// api请求地址
	// baseUrl: 'https://test.dh958.com',
	baseUrl: 'https://soppvv.com',
	baseShopUrl: 'https://shop.soppvv.com',
	// baseUrl: 'http://localhost:9090/index.php',

	// 图片域名
	// imgDomain: 'https://test.dh958.com',
	imgDomain: 'https://soppvv.com',
	// imgDomain: 'http://localhost:9090',

	// 腾讯地图key
	mpKey: '',

	// 客服
	webSocket: 'ws://localhost:9090',

	// api安全
	apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: ``,

}
